


@media (min-width: 280px) and (max-width: 400px) {
    .change {
        width: 100% !important;
        height: 500px !important;
    }
}
@media (min-width: 400px) and (max-width: 500px) {
    .change {
        width: 380px !important;
        height: 380px !important;
    }
}
@media (min-width: 500px) and (max-width: 640px) {
    .change {
        width: 450px !important;
        height: 450px !important;
    }
}