
@media screen and (max-width: 950px) {
    table {
      border: 0;
    }
  
    table caption {
      font-size: 1.3em;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 2.625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
   
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }

  @media (min-width: 280px) and (max-width: 400px) {
    .line {
      top: 2.9rem;
    }
  }

  @media  (min-width: 400px) and (max-width: 420px) {
    .line {
      top: 3.5rem;
    }
  }

  @media (min-width: 420px) and (max-width: 508px) {
    .line {
      top: 3.5rem;
    }
  }