@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


*:focus {
    outline: none;
}

.empty {
    height: 100vh;
    width: 100%;
    background: #00000082;
    position: fixed;
    z-index: 11;
}

.yesorno {
    padding: 0.4rem 1.4rem;
}

body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden !important;
}


#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
    height: 8px;
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}


@media (min-width: 320px) and (max-width: 650px) {
    #supported-modal {
        width: 290px;
    }
};

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@media (max-width: 280px) {
    .sidebar-modal {
        width: 100% !important;
    }

}

.loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 100px;
    color: #FFF;
    margin: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.loading span {
    position: absolute;
    height: 10px;
    width: 84px;
    top: 50px;
    overflow: hidden;
}
.loading span > i {
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    -webkit-animation: wait 4s infinite;
    -moz-animation: wait 4s infinite;
    -o-animation: wait 4s infinite;
    animation: wait 4s infinite;
}
.loading span > i:nth-of-type(1) {
    left: -28px;
    background: yellow;
}
.loading span > i:nth-of-type(2) {
    left: -21px;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: lightgreen;
}

@-webkit-keyframes wait {
    0%   { left: -7px  }
    30%  { left: 52px  }
    60%  { left: 22px  }
    100% { left: 100px }
}
@-moz-keyframes wait {
    0%   { left: -7px  }
    30%  { left: 52px  }
    60%  { left: 22px  }
    100% { left: 100px }
}
@-o-keyframes wait {
    0%   { left: -7px  }
    30%  { left: 52px  }
    60%  { left: 22px  }
    100% { left: 100px }
}
@keyframes wait {
    0%   { left: -7px  }
    30%  { left: 52px  }
    60%  { left: 22px  }
    100% { left: 100px }
}

@media (max-width: 334px) {
    .crap {
        justify-content: space-between;
        width: 100%;
    }
    .dip {
        width: 100%;
    }
    .head {
        text-align: end;
    }
}

@media (max-width: 500px) {
    .statusModal {
        max-width: 100%;
        width: 100%;
    }
    /* .card-inner {
        flex-direction: column;
        align-items: center;
    } */
}

@media (max-width: 320px) {
    .after320 {
         flex-wrap: wrap;
         justify-content: center;
    }
    .small-size {
        width: 100%;
    }
    .hiddenOnSm {
            display: none;
    }
}

@media (min-width:1600px) {
    .packageCard {
        width: 30% !important;
    }
}

@media (min-width: 600px) and (max-width: 640px) {
    .packageCard {
        width: 45% !important;
    }
}

@media (min-width: 280px) and (max-width: 360px) {
    .stepwBtnVerify {
        width: 49% !important;
    }
}
@media (min-width: 360px) and (max-width: 640px) {
    .stepwBtnVerify {
        width: 35% !important;
    }
}


@media (min-width: 280px) and (max-width: 300px) {
    .id {
        font-size: 0.7rem !important;
    }
}

@media (max-width: 400px) {
    .paymentTabBtn {
        font-size: 0.8rem !important;
    }
}

@media (max-width: 440px) {
    .localImg {
        height: 300px !important;
    }
}


@media (min-width: 700px) and (max-width: 950px) {
    .my-order-card {
        width: 46% !important;
    }
}



@media (max-width: 1240px) {
    .slick-slider {
        display: block !important;
        max-width: 100% !important;
    }
    .slider-cus {
        background-color: #155366;
    }
    .slick-prev{
       left: 76px !important;
       top: 125% !important;
    }
    .slick-next{
       right: 75px !important;
       top: 125% !important;

    }
    .slick-dots > li > button::before {
        color: white !important;
    }
    .slick-prev, .slick-next {
        bottom: 125% !important;
    }
 .slick-prev::before {
    color: black !important;
 }
 .slick-next::before {
    color: black !important;
 }
};



